"use strict";

/**
 * Based on GTM consent mode v2. 
 * see: https://developers.google.com/tag-platform/security/guides/consent?consentmode=advanced
 * see: https://developers.google.com/tag-platform/security/guides/consent#implementation_example
 */
// Define dataLayer and the gtag function.
window.dataLayer = window.dataLayer || [];

function gtag() {
  dataLayer.push(arguments);
}

window.or_gtm_default = {
  'ad_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'analytics_storage': 'denied',
  'functionality_storage': 'granted',
  'personalization_storage': 'granted',
  'security_storage': 'granted',
  'wait_for_update': 500
};

function or_gtm_setDefault() {
  const obj = or_gtm_consentSet() ? or_gtm_getConsentAll() : window.or_gtm_default;
  gtag('consent', 'default', obj);
  or_gtm_setConsentAll(obj);
}

or_gtm_setDefault(); // This code should be executed _before_ GTM head/body script

function or_gtm_getDefault() {
  return window.or_gtm_default;
}
/**
 * Update consent e.g.
 * @param {*} obj 
 * 'ad_storage': 'granted'
 */


function or_gtm_UpdateConsent(obj) {
  gtag('consent', 'update', obj);
}
/**
 * Locally store user preferences
 */


function or_gtm_consentSet() {
  return typeof localStorage.getItem('consent') != 'undefined' && 'undefined' != localStorage.getItem('consent') && localStorage.getItem('consent');
}

function or_gtm_getConsentAll() {
  if (or_gtm_consentSet()) {
    const consent = JSON.parse(localStorage.getItem('consent'));
    return consent;
  } else {
    console.info('Consent not set');
  }
}

function or_gtm_getConsent(key) {
  if (or_gtm_consentSet()) {
    const consent = JSON.parse(localStorage.getItem('consent'));
    return consent[key] === 'granted';
  } else {
    console.info('Consent not set');
  }
}

function or_gtm_toggleConsent(key) {
  if (or_gtm_getConsent(key)) {
    or_gtm_setConsent(key, 'denied');
  } else {
    or_gtm_setConsent(key, 'granted');
  }

  return or_gtm_getConsent(key);
}

function or_gtm_initConsent(obj) {
  localStorage.setItem('consent', JSON.stringify(obj));
  or_gtm_UpdateConsent(obj);
}

function or_gtm_setConsentAll(obj) {
  localStorage.setItem('consent', JSON.stringify(obj));
}

function or_gtm_setConsent(key, value) {
  if (or_gtm_consentSet()) {
    var obj = JSON.parse(localStorage.getItem('consent'));
    obj[key] = value;
    localStorage.setItem('consent', JSON.stringify(obj));
    or_gtm_UpdateConsent(obj);
  } else {
    console.error('OR GTM: Local consent not set.');
  }
}

function or_gtm_user_consented() {
  return typeof localStorage.getItem('user_consented') != 'undefined' && 'undefined' != localStorage.getItem('user_consented') && localStorage.getItem('user_consented');
}

function or_gtm_set_user_consent() {
  localStorage.setItem('user_consented', true);
}

module.exports = {
  or_gtm_setDefault,
  or_gtm_getDefault,
  or_gtm_UpdateConsent,
  or_gtm_consentSet,
  or_gtm_getConsentAll,
  or_gtm_getConsent,
  or_gtm_toggleConsent,
  or_gtm_initConsent,
  or_gtm_setConsentAll,
  or_gtm_setConsent,
  or_gtm_user_consented,
  or_gtm_set_user_consent
};